import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import AboutMeView from '@/views/AboutMeView.vue';
import ProjectsView from '@/views/ProjectsView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'about-me',
  },
  {
    path: "/about-me",
    name: "about-me",
    component: AboutMeView
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;