<template>
  <nav-component v-bind:class="{ 'add-box-shadow': isScrolled && !isMenuVisible, 'padding-right': isMenuVisible }"
    @change-menu-visibility="changeMenuVisibility"></nav-component>
  <transition>
    <menu-component v-if="isMenuVisible" @change-menu-visibility="changeMenuVisibility()"></menu-component>
  </transition>
  <div class="container">
    <div class="disable-container" v-if="isMenuVisible" v-on:click="changeMenuVisibility"></div>
    <div class="content-container" v-bind:class="{ 'decrease-opacity': isMenuVisible }">
      <main>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </main>
      <footer>
        <span>
          duplaem.hu <font-awesome-icon :icon="['fas', 'copyright']" /> 2024
        </span>
      </footer>
    </div>
  </div>

</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand&family=Raleway&display=swap");
@import "@/assets/styles/_variables.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: $primary-font;
  color: $text-color;
}

html {
  height: 100%;
  width: 100%;
}

body {
  background-color: $main-color;
}

a {
  text-decoration: none;
  color: $text-color;
  width: fit-content;
}

p {
  margin-bottom: 1rem;
}

small {
  display: block;
  font-size: 0.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $secondary-font;
  margin-bottom: 1rem;
}

ol {
  margin: 1rem 0;

  li {
    margin-left: 2rem;
  }
}

a,
.menu-link,
.close-button,
.icon-link {
  cursor: pointer;
}

.title-icon {
  margin-right: 0.5rem;
}

.icon-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;

  .icon {
    height: 3rem;
  }
}

@mixin card-base {
  .card-header {
    margin-bottom: 1.5rem;
    border-bottom: 0.125rem solid $text-color;
  }
}

.card {
  @include card-base;
}

.close-button {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  background-color: $pop-color;
  border: 0.25rem solid $dark-color;

  .close-icon {
    height: 1.5rem;
  }
}

.container {
  height: calc(100% - $navbar-height);
  margin-top: $navbar-height;
}

.content-container {
  height: 100%;
  transition: opacity 0.25s ease-in-out;
}

.info-bubble {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .info-icon {
    height: 1.25rem;
  }

  small {
    text-decoration: underline;
  }
}

.add-box-shadow {
  box-shadow: $my-box-shadow;
}

.decrease-opacity {
  opacity: 0.25;
}

.disable-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.disable-scroll {
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  width: $scrollbar-width;

  scrollbar-gutter: unset;
}

::-webkit-scrollbar-thumb {
  background-color: $pop-color;
  border-radius: $scrollbar-width / 2;
}

// MEDIA QUERIES
@media screen and (orientation: portrait),
(max-width: 768px) {
  .disable-container {
    z-index: 97;
  }

  main {
    padding: 2.5rem 1.25rem;
  }

  .icon-link {
    color: $text-color;
  }

  footer {
    padding: 2.5rem 1.25rem;
    border-top: 0.125rem dashed $dark-color;
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  .disable-container {
    z-index: 98;
  }

  .disable-scroll {
    padding-right: $scrollbar-width;
  }

  .padding-right {
    padding-right: $scrollbar-width;
  }

  main {
    width: 70%;
    margin: auto;
    padding: 2.5rem 0;
  }

  a:not(#logo):not(.router-link-active):not(.router-link-exact-active):not(.icon-link) {
    border-bottom: 0.125rem transparent solid;
    transition: border 0.25s ease-in-out;

    &:hover {
      border-bottom: 0.125rem $text-color solid;
    }
  }

  .icon-link {
    color: $pop-color;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $text-color;
    }
  }

  @mixin card-base {
    background-image: linear-gradient(135deg,
        lighten($dark-color, 0.5%) 38%,
        $dark-color 42%);
    border-radius: 0.5rem;
    box-shadow: $my-box-shadow-smaller;
  }

  .card {
    @include card-base;

    padding: 2.5rem;
    border: 0.125rem solid $dark-color;
    transition: border 0.25s ease-in-out, transform 0.25s ease-in-out,
      box-shadow 0.25s ease-in-out;

    &:hover {
      border: 0.125rem solid $text-color;
      //transform: scale(101%);
      //box-shadow: $my-box-shadow;
    }
  }

  .close-button {
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      background-color: $text-color;
      color: $pop-color;
    }
  }

  footer {
    padding: 1.25rem;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { isScrolled: false, isMenuVisible: false }
  },
  methods: {
    handleScroll(event: any): void {
      const scrollPosition: number = window.scrollY;

      if (scrollPosition > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    changeMenuVisibility() {
      const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];

      this.isMenuVisible = !this.isMenuVisible;

      if (this.isMenuVisible == true) {
        html.classList.add('disable-scroll');
      } else {
        html.classList.remove('disable-scroll');
      }
    }
  },
  computed: {
    getIsMenuVisible(): boolean {
      return this.isMenuVisible;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
</script>
