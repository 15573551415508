<template>
  <div>
    <div class="projects-container">
      <div class="card">
        <div class="card-header">
          <h3>duplaem.hu</h3>
        </div>
        <div class="card-body">
          <img src="@/assets/img/mockups/duplaem_product_mockup.png" alt="duplaem.hu" />
          <div>
            <h4>Leírás:</h4>
            <p>
              Eredetileg egy Vue tanulós hobbiprojektnek indult, ami Firebase integrációval egy egyszerű CMS rendszer
              lett
              volna. Illetve volt is, de 1 évvel később, amikor úgy döntöttem, hogy eleget fizettem feleslegesen a
              domainért azért, hogy egy "hamarosan..." feliratra mutasson, kihúztam minden felesleges elemet és
              portfólió oldallá
              avanzsált.
            </p>
            <h4>Felhasznált technológiák:</h4>
            <ul>
              <li>Vue 3</li>
              <li>SCSS</li>
              <li>Firebase (csak hosting)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="projects-container">
      <div class="card" onclick="window.open('https://favagaskomarom.hu', '_blank')">
        <div class="card-header">
          <h3>Szalai Favágás Komárom</h3>
        </div>
        <div class="card-body">
          <img src="@/assets/img/mockups/favagas_komarom_product_mockup.png" alt="Szalai Favágás Komárom" />
          <div>
            <h4>Leírás:</h4>
            <p>
              A Szalai Favágás Komárom csapatának készült egyszerű bemutatkozó
              oldal.
            </p>
            <h4>Felhasznált technológiák:</h4>
            <ul>
              <li>Vue 3</li>
              <li>Tailwind CSS</li>
              <li>Firebase (csak hosting)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.projects-container {
  margin-bottom: 2.5rem;

  .card {
    cursor: pointer;
  }

  .card-body {
    display: grid;
    gap: 1.25rem;

    img {
      width: 100%;
      filter: drop-shadow(0 0.5rem 0.125rem rgba(0, 0, 0, 0.125));
    }

    li {
      margin-left: 1rem;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  .projects-container {
    .card-body {
      grid-template-columns: 1fr 2fr;
      align-items: center;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    window.scrollTo(0, 0);
  }
});
</script>